.App {
  text-align: center;
}
body {
  background-image: linear-gradient(
      to bottom,
      rgb(0, 0, 0, 0.5),
      rgb(0, 0, 0, 0.5)
    ),
    url(/static/media/bg.76cca909.png) !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center center !important;
}
.gif__container {
  width: 100%;
}
.gif {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  height: auto;
  margin-bottom: 3vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  height: auto;
  margin-bottom: 4vh;
  margin-top: 0vh;
}

body {
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

